import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4×4\\@80%1RM`}</p>
    <p>{`Strict Pullups 4×4`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time:`}</p>
    <p>{`5-Bar Muscle Ups (scale to 10 pullups)`}</p>
    <p>{`10-KBS’s (53/35)(RX+ 70/53)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`20-Pushups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      